.sektion-5-outercontainer {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	background-color: var(--main-color);
	padding: 0px;
	margin: 0;
	align-items: center;
	width: 100%;
}
.sektion-5-Button {
	width: 200px;
}
.sektion-5-picture-container {
	width: 100%;
}
.sektion-5-picture {
	background-image: url("../bilder/large/sektion5Bild.jpg");
	height: 65vh;
	background-size: cover;
	background-position: center;
	margin: 0;
}
.sektion-5-text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	padding: 40px;
	width: auto;
	min-height: 70vh;
}

#forelasning {
	margin-top: 50px;
	margin-bottom: 50px;
}

@media screen and (min-width: 800px) {
	.sektion-5-outercontainer {
		flex-direction: row-reverse;
		padding-right: 20px;
	}
	.sektion-5-picture {
		width: 45vw;
		margin-right: 20px;
	}
	.sektion-5-text-container {
		width: 50wv;
	}
}
