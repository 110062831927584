/* :root {
	--main-color-transparent: #aeaeb642;
	--secondary-color: #b0b6ae;
	--dark-shadow: #6b6b73;
	--light-shadow: #b7b7c0;
	--text-color: #95959f;
	--text-color-contast: #000000;
	--text-color-invers: #ffffff;
	--filtersetting: grayscale(20%) contrast(120%) brightness(115%);
	--filtersetting3: grayscale(20%) contrast(120%) brightness(115%);
} */

.heroContainer {
	height: 100svh;
}
.heroImagecontainer {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
	background-position: 40%, 20%;
	height: 100%;
	width: 100%;
	transition: all 2s;
	filter: var(--filtersetting3);
	background-image: linear-gradient(#08302a9a, #0f3e3777),
		url("../bilder/large/andreasHero.jpg");
}
.heroTextruta {
	text-align: left;
	margin: auto;
	margin-bottom: 50px;
	width: 60%;
	background-color: var(--main-color-trans);
	padding: 5%;
	border-radius: 20px;
	backdrop-filter: blur(5px);
	overflow: hidden;
}
.heroButton {
	float: right;
	margin-right: 10px;
	background-color: rgba(250, 250, 250, 0.1);
	border-radius: 8px;
	color: black;
}
.heroH1 {
	font-size: 2em;
	font-weight: 200;
}
.heroButton:hover {
	cursor: pointer;
	background-color: rgba(250, 250, 250, 0.5);
}
.heroButton:active {
	background-color: rgba(50, 50, 50, 0.2);
	color: rgb(255, 255, 255);
}
.heroLinje {
	background-color: var(--contrast-color);
	height: 2px;
	width: 100%;
	margin: 0;
}
.meny {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.hide {
	display: none;
}
#efternamn {
	font-style: italic;
	font-weight: 100;
}
#logga2 {
	width: 80px;
	float: right;
	border-radius: 5px;
}
#contaktmail {
	float: right;
	background-color: white;
	padding: 10px;
	border-radius: 10px;
	text-decoration: none;
	color: black;
}
/*------------BIG-----------------*/
@media only screen and (min-width: 800px) {
	.meny {
		display: flex;
		flex-direction: row;
	}
	.heroImagecontainer {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		background-position: 50%;
	}
	.heroTextruta {
		text-align: left;
		margin: 2%;
		width: 40%;
		background-color: var(--dark-color-trans);
		padding: 5%;
		border-radius: 20px;
		backdrop-filter: blur(5px);
		color: var(--main-color);
		color: var(--main-color);
	}
}
/*---------xs ---------------- */

@media only screen and (max-width: 450px) {
	.heroH1 {
		font-size: 1em;
	}
}
