/* Xscrollstyle.css */

.xscrollouter {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100px;
}
.xscrollouter h1 {
	padding: 20px;
	background-color: white;
}
.XscrollContainer {
	width: auto;
	margin-top: 20px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
}

.innerXscrollContainer {
	width: 60%;
	display: flex;
	overflow-x: auto;
	scroll-behavior: smooth;
	border: 3px solid #ccc;
	border-radius: 5px;
	padding: 20px;
}
.innerXscrollContainer::-webkit-scrollbar {
	width: 0;
	height: 0;
	/* This will hide the scrollbar in Chrome on Mac */
}
.XscrollCard {
	flex: 0 0 auto;
	width: 300px; /* Adjust the card width as needed */
	margin-right: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: rgba(200, 200, 200, 0.9);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.xscrollCardInner {
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.XscrollCardPicture {
	width: 90%;
	height: 200px; /* Adjust the picture height as needed */
	object-fit: cover;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.cardtitle {
	font-size: 1.2rem;
	margin-top: 10px;
}

.cardtext {
	font-size: 0.9rem;
	color: #666;
	margin-top: 5px;
}

.carousel-controls {
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.carousel-controls button {
	padding: 5px 10px;
	font-size: 18px;
	background-color: #f9f9f9;
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: pointer;
	margin: 0 5px;
	width: 50px;
	height: 50px;
}

.carousel-controls button:hover {
	background-color: #eaeaea;
}
