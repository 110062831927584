:root {
  --main-color: #dddddd;
  --dark-shadow: #a0a0a0;
  --light-shadow: #e8e8e8;

  --text-color: #707070;
}

@font-face {
  font-family: "campton";
  src: url(./CamptonBook.otf);
}
@font-face {
  font-family: "camptonlight";
  src: url(./CamptonLight.otf);
}
@font-face {
  font-family: "camptonM";
  src: url(./CamptonMedium.otf);
}
.loginHeaderDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.seetoggle {
  max-height: 10px;
}

.loginContainer {
  background-color: var(--main-color);
  font-family: "campton";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  max-width: 600px;
  align-items: left;
  text-align: left;
  margin: 20px;
  padding: 30px;
  border-radius: 10px;
  border: none;
  box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.3);
}

.specialLoginInput {
  margin-bottom: 20px;
  outline-width: 0;
  margin-top: 5px;
  border: none;
  border-style: none;
  border-color: none;
  border-radius: 10px;
  padding: 10px;
  background-color: var(--light-shadow);
  color: var(--text-color);
  font-size: 1em;
  box-shadow: inset 2px 2px 2px var(--dark-shadow),
    inset -2px -2px 2px var(--light-shadow);
}

.specialLoginButton {
  font-family: "camptonM";
  border: none;
  color: black;
  display: flex;
  font-size: 1.1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px;
  width: 100px;
  height: 80px;
  padding: 20px;
  border-radius: 10px;
  background: var(--main-color);
  transition: 500ms;

  cursor: pointer;
  box-shadow: -2px -2px 2px var(--light-shadow), 2px 2px 2px var(--dark-shadow),
    inset 2px 2px 10px var(--main-color), inset -2px -2px 10px var(--main-color);
}
.specialLoginButton:hover {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px;
  width: 100px;
  height: 80px;
  padding: 20px;
  border-radius: 10px;
  background: var(--main-color);
  transition: 500ms;
  box-shadow: -2px -2px 2px var(--light-shadow), 2px 2px 2px var(--dark-shadow),
    inset 2px 2px 10px var(--main-color), inset -2px -2px 10px var(--main-color);
}

.specialLoginButton:active {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 30px;
  color: white;
  width: 100px;
  height: 80px;
  padding: 20px;
  border-radius: 10px;
  transition: 500ms;
  background: var(--main-color);
  box-shadow: -5px -5px 15px var(--main-color), 5px 5px 15px var(--main-color),
    inset 5px 5px 10px var(--dark-shadow),
    inset -5px -5px 10px var(--light-shadow);
}

.loginButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.bottomSegment {
  display: flex;
  flex-direction: column;
  text-align: center;
}
