.footerContainer {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: var(--main-color);
	width: 100%;
	margin-top: 20px;
	z-index: 3;
}
.socialpicFooter {
	max-width: 40px;
	margin: 20px;
}
