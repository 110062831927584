.contactFormSektion {
	position: relative;
	z-index: 2;
	height: 75vh;
}

.formContainer {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 20px;
	background-color: var(--main-color-transparent);
	border-radius: 20px;
	min-height: 310px;
	width: 300px;
	margin-bottom: 20px;
}
.formButton {
	cursor: pointer;
}
.contactFormBackground {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin-bottom: 20px;
	background-image: url("../bilder/large/andreasKontakt.jpg");
	background-size: cover;
	background-position: 0%;
	padding: 20px;
}

.inputForm {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.formButton {
	margin-top: 10px;
	background-color: rgba(250, 250, 250, 0.4);
	border-radius: 8px;
	width: 100px;
	justify-self: end;
}

.confirmation {
	position: absolute;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--main-color);
	width: 100%;
	height: 300px;
	border-radius: 20px;
	padding: 20px;
}

#closeConfirmation {
	position: absolute;
	cursor: pointer;
	top: 10px; /* Adjust the top position as needed */
	right: 20px; /* Adjust the right position as needed */
}

/* minst */

@media only screen and (min-width: 600px) {
	.contactformPicture {
		display: none;
	}
	.contactFormBackground {
		align-items: flex-start;
	}
	.formContainer {
		margin-left: 30px;
	}
}
