/* width */
::-webkit-scrollbar {
	width: 20px;
	height: 20px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px var(--main-color);
	border-radius: 0px 0px 10px 10px;
	background-color: var(--main-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--dark-color);
	border-radius: 5px;
}

.reviewSektionen {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.reviewOuterContainern {
	display: flex;
	flex-direction: row;
	padding: 100px 10px;
	align-items: center;
	justify-content: flex-start;
	margin: 0px 10px 20px 10px;
	gap: 60px;
	background-color: var(--dark-color-trans);
	overflow-x: scroll;
	width: 90%;
	border: solid;
	border-radius: 10px;
}

.reviewBackground {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;
	background-image: linear-gradient(#e2e9e89a, #e2e9e877),
		url("../bilder/_DSC0971.jpg");
	z-index: -1;
	bottom: 0;
	height: 110svh;
	width: 100vw;
}

/* SEKTION BILD OCH TEXT */
.sektion-3-text-bild {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* gap: 20px; */
	/* min-height: 70svh; */
	width: auto;
}
.sektion-3-text-container {
	display: flex;
	flex-direction: column;
	padding: 20px;
	justify-content: center;
	width: auto;
	/* min-height: 70svh; */
}
.sektion-3-text {
	background-color: var(--main-color-trans);
	backdrop-filter: blur(3px);
	border-radius: 20px;
	padding: 20px;
	border: solid;
	border-color: var(--dark-color);
}
.sektion-3-picture-container {
	display: none;
	height: 100%;
}
#sektion-3-picture {
	background-image: url("../../src/bilder/large/vadkanjag.jpg");
	background-size: cover;
	background-position: center;
	width: 50vw;
	min-height: 50svh;
	border-radius: 10px;
}

.reviewTextContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}
.reviewTextContainer p {
	flex-grow: 1;
}

.reviewSmallContainern {
	display: flex;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(220, 220, 220, 0.8);
	border-radius: 20px;
	padding: 20px;
	border: solid;
	border-color: var(--dark-color);
	min-height: 400px;
	min-width: 200px;
}

.reviewPictureContainer {
	position: absolute;
	background-size: cover;
	background-position: 50%;
	border-radius: 100px;
	background-size: cover;
	top: -60px;
	min-width: 100px;
	aspect-ratio: 2/2;
}

#a .reviewPictureContainer {
	background-image: url("../bilder/vikter2.png"),
		linear-gradient(rgba(250, 250, 250, 0.8), rgba(250, 250, 250, 0.8));
}
#b .reviewPictureContainer {
	background-image: url("../bilder/kalender2.png"),
		linear-gradient(rgba(250, 250, 250, 0.8), rgba(250, 250, 250, 0.8));
}
#c .reviewPictureContainer {
	display: flex;
	background-color: rgba(250, 250, 250, 0.8);
	background-image: url("../bilder/large/selfreflect.png");
	background-size: 60%;
	background-repeat: no-repeat;
	width: 100px;
	height: 100px;
}
#d .reviewPictureContainer {
	display: flex;
	background-color: rgba(250, 250, 250, 0.8);
	background-image: url("../bilder/large/Checklist.png");
	background-size: 60%;
	background-repeat: no-repeat;
	width: 100px;
	height: 100px;
}

@media screen and (min-width: 600px) {
	.sektion-3-picture-container {
		display: flex;
	}

	.sektion-3-text-bild {
		flex-direction: row;
		width: 90%;
		gap: 20px;
		min-height: 70svh;
	}
}
