:root {
  --main-color: #dddddd;
  --dark-shadow: #a0a0a0;
  --light-shadow: #e8e8e8;

  --text-color: #707070;
}

@font-face {
  font-family: "campton";
  src: url(./CamptonBook.otf);
}

@font-face {
  font-family: "camptonBold";
  src: url(./CamptonBold.otf);
}
@font-face {
  font-family: "camptonM";
  src: url(./CamptonMedium.otf);
}
@font-face {
  font-family: "camptonlight";
  src: url(./CamptonLight.otf);
}

.navbar {
  display: flex;
  background-color: var(--main-color);
}
ul {
  display: flex;
  list-style-type: none;
}

li {
  text-decoration: none;
  font-style: none;
  font-family: "campton";
  color: var(--text-color);
}

.links {
  text-decoration: none;
  font-style: none;
  color: var(--text-color);
}
