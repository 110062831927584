.sektion-4-outercontainer {
	display: flex;
	flex-direction: column;
	background-color: var(--main-color);
	padding: 0px;
	margin: 0;
	align-items: center;
	width: 100%;
}
.sektion-4-Button {
	width: 200px;
}
.sektion-4-picture-container {
	width: 100%;
}
.sektion-4-picture {
	background-image: url("../bilder/large/why.jpg");
	width: auto;
	height: 75vh;
	background-size: cover;
	background-position: center;
	margin: 0;
}
.sektion-4-text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
	width: auto;
	min-height: 70vh;
	padding-left: 40px;
	padding-right: 40px;
}

@media screen and (min-width: 800px) {
	.sektion-4-outercontainer {
		flex-direction: row;
	}
	.sektion-4-picture {
		width: 50vw;
	}
	.sektion-4-text-container {
		width: 50wv;
	}
}
