:root {
	--dark-color: #262626;
	--dark-color-trans: #26262693;
	--medium-color: #536c69;
	--main-color: #e2e9e8;
	--main-color-trans: #e2e9e89c;
	--main-color-filter: #e2e9e8de;
	--main-color-filter2: rgba(226, 233, 232, 0.955);
	--lighter-color: #fcfdfd;
	--contrast-color: #ee7203;
}
