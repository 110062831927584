@font-face {
	font-family: "camptonlight";
	src: url(./CamptonLight.otf);
}
@font-face {
	font-family: "camptonBook";
	src: url(./CamptonBook.otf);
}
@font-face {
	font-family: "Trajan";
	src: url(./Trajan\ Pro.ttf);
}

.hide-calendar {
	display: none;
}
.calendar {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	z-index: 50;
	right: 0px;
	padding-top: 50px;
	padding-bottom: 0;
	background-color: white;
	width: 100vw;
}

#calendar-x {
	background-color: white;
	float: right;
	font-size: 2em;
	cursor: pointer;
}

/* Egna variabler */
:root {
	--main-color-transparent: #aeaeb642;
	--secondary-color: #b0b6ae;
	--dark-shadow: #6b6b73;
	--light-shadow: #b7b7c0;
	--text-color: #95959f;
	--text-color-contast: #000000;
	--text-color-invers: #ffffff;
	--filtersetting: grayscale(20%) contrast(120%) brightness(115%);
}

/* läggs till på detta sätt:  background-color: var(--main-color);  */
h1,
h2,
h3 {
	font-family: Trajan;
}

p {
	font-family: camptonBook;
}
.navbar {
	position: fixed;
	margin-top: 1em;
	background-color: #aeaeb6dd;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 40;
	border-radius: 10px;
	margin-left: 1em;
	backdrop-filter: blur(6px);
}
#hamburger {
	padding: 10px;
	margin: auto;
}
.bild-logga {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	overflow: hidden;
}
#logga {
	display: none;
	width: 50px;
	scale: 1.7;
	margin: 8px;
	border-radius: 10px;
}
.navbar ul {
	text-align: left;
	margin: 0;
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 100%;
}
.navbar li {
	width: 100%;
	font-size: 1.2em;
	justify-content: center;
	padding-right: 20px;
}
.navbar li a {
	text-decoration: none;
	font-style: none;
	color: var(--main-color);
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-weight: 300;
	font-size: 1.2em;
	padding: 10px;
	cursor: pointer;
	margin: 0px;
}

#storelink img {
	width: 30px;
	height: 30px;
}
#storelink {
	color: var(--main-color);
	padding: 0px;
	font-size: 1.2em;
	font-weight: 300;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	margin: 0px;
	cursor: pointer;
	padding-left: 10px;
}
#storelink p {
	margin: 0px;
	padding: 0px;
	color: var(--li);
	font-size: 1.2em;
	font-weight: 300;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	text-align: left;
}

/*------------BIG-----------------*/

@media only screen and (min-width: 800px) {
	.calendar {
		padding: 20px;
		width: 50vw;
	}
	.navbar {
		/* position: absolute; */
		margin-top: 1em;
		background-color: transparent;
		z-index: 4;
		width: 100%;
		margin-left: 0;
		z-index: 10;
	}
	.bild-logga {
		margin-left: 20px;
	}

	#storelink {
		float: right;
		cursor: pointer;
		padding: 10px;
	}
	#storelink p {
		padding: 10px;
		display: inline;
	}
	#storelink img {
		height: 40px;
		width: 40px;
	}
	.navbar ul {
		display: flex;
		justify-content: center;
		align-items: center;
		white-space: nowrap;
	}
	.navbar ul li a,
	.navbar ul li p {
		background-color: var(--dark-shadow);
		border-radius: 10px;
	}
	#hamburger {
		background-color: #000000;
		display: none;
	}
	#logga {
		display: block;
		cursor: default;
	}
	#kryss {
		display: none;
	}
	.navbar ul li a:hover {
		text-decoration: none;
		font-style: none;
		color: black;
		font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
			Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
			sans-serif;
		font-weight: 300;
		font-size: 1.2em;
		background-color: var(--secondary-color);
		border-radius: 5px;
		padding: 10px;
	}

	#storelink p:hover {
		color: black;
		padding: 10px;
		font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
			Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
			sans-serif;
		background-color: var(--secondary-color);
		border-radius: 5px;
	}
}
