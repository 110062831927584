/* för att ej få länk text i ios */

:root {
	--main-color: #aeaeb6;
	--main-color-transparent: #d9d9e6b1;
	--secondary-color: #b0b6ae;
	--dark-shadow: #6b6b73;
	--light-shadow: #b7b7c0;
	--text-color: #95959f;
	--text-color-contast: #000000;
	--text-color-invers: #ffffff;
	--filterSetting2: grayscale(100%) contrast(10%) brightness(1%);
	--filterSetting3: grayscale(20%) contrast(100%) brightness(90%);
	/* --filtercolor: rgba(251, 255, 121, 0.2); */
}
p {
	font-family: "Courier New", Courier, monospace;
}
button {
	color: black;
}
.sektion2OuterContainer {
	position: relative;
	z-index: 4;
}

.sektion2RightContainer {
	background-image: linear-gradient(
			var(--main-color-filter2),
			var(--main-color-filter2)
		),
		url("../bilder/Ny1VitFYLD2.png");
	background-repeat: repeat;
	background-size: 3%;
	background-position: 0%;
}
.sektion2RightContainerPicture {
	grid-area: picture;
	height: 100%;
	overflow: hidden;
}
.sektion2RightPicture {
	grid-area: picture;
	background-size: cover;
	background-position: 50%;
	height: 70vh;
	transform: scale(1.3);
	transition: all 2s;
	filter: var(--filterSetting3);
}

.sektion2RightPicture::before {
	content: "";
	position: absolute;
	left: 0;
	height: 100%;
	width: 100%;
	background: var(--filtercolor);
	transition: all 2s;
}

.sektion2RightPicture:hover {
	background-size: cover;
	background-position: 40%;
	transform: scale(1.3);
	transition: all 2s;
}

.sektion2RightText {
	grid-area: texten;
	text-align: left;
	opacity: 0;
	transition: all 2s;
	text-align: left;
	opacity: 0;
	transition: all 2s;
	backdrop-filter: blur(2px);
	border-radius: 5px;
	width: 80%;
	padding: 20px;
	margin: 20px;
}

.Sektion2RightButton {
	float: right;
	margin-right: 10px;
	background-color: rgba(250, 250, 250, 0.1);
	border-radius: 8px;
}
.sektion2RightTextContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 70svh;
}
.Sektion2RightButton:hover {
	cursor: pointer;
}
.Sektion2RightButton:active {
	background-color: rgba(50, 50, 50, 0.2);
	color: rgb(255, 255, 255);
}
.sektion2LeftContainer {
	background-image: linear-gradient(
			rgba(210, 228, 251, 0.965),
			rgba(210, 228, 251, 0.95)
		),
		url("../bilder/Ny1VitFYLD2.png");
	background-repeat: repeat;
	background-size: 3%;
	background-position: 0%;
}
.sektion2LeftPicture {
	grid-area: picture;
	background-size: cover;
	background-position: 50%;
	height: 70vh;
	transform: scale(1.3);
	transition: all 2s;
}
.sektion2LeftPicture:hover {
	background-size: cover;
	background-position: 40%;
	transform: scale(1.2);
	transition: all 4s;
}
.sektion2LeftContainerPicture {
	grid-area: picture;
	height: 100%;
	overflow: hidden;
}

.sektion2LeftText {
	grid-area: texten;
	text-align: left;
	padding: 10%;
	opacity: 0;
	transition: all 2s;
	text-align: left;
	padding: 10%;
	opacity: 0;
	transition: all 2s;
	backdrop-filter: blur(2px);
	border-radius: 5px;
	width: 80%;
	padding: 20px;
	margin: 20px;
}

.sektion2LeftTextContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 70svh;
}

.Sektion2LeftButton {
	float: right;
	margin-right: 10px;
	background-color: rgba(250, 250, 250, 0.1);
	border-radius: 8px;
}
.Sektion2LeftButton:hover {
	cursor: pointer;
}
.Sektion2LeftButton:active {
	background-color: rgba(50, 50, 50, 0.2);
	color: rgb(255, 255, 255);
}

#pictureRight {
	background-image: url("../bilder/large/vadmental.jpg");
}
#pictureLeft {
	background-image: url("../bilder/glida.png");
}
/*För effekter */
.textrutashow {
	opacity: 1;
}

.picturedivzoom {
	background-size: cover;
	background-position: 48%;
	transform: scale(1);
	transition: all 3s;
}

/* ---- SPecial */
.cursivt {
	font-size: large;
	font-style: bold;
}

/*--------Mobile-----------------*/
@media only screen and (max-width: 800px) {
	.sektion2RightPicture {
		height: 70vh;
	}
	.sektion2LeftPicture {
		height: 70vh;
	}
}

/*------------BIG-----------------*/
@media only screen and (min-width: 800px) {
	.sektion2RightContainer {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-areas: "picture texten";
		align-items: center;
	}

	.sektion2RightPicture {
		height: 100%;
	}

	.sektion2LeftContainer {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-areas: "texten picture";
		align-items: center;
	}
	.sektion2LeftText {
		margin: 20px;
	}
	.sektion2RightText {
		margin: 20px;
	}
	.sektion2LeftPicture {
		height: 100%;
	}
}
